import ModelLoader from "./ModelLoader";
import vertexShader from "../shaders/point.vert";
import fragmentShader from "../shaders/point.frag";
import driftVert from "../shaders/drift.vert";
import { clamp, relativeProgress as rp } from "../../util/math";

import {
	BufferGeometry,
	BufferAttribute,
	Points,
	ShaderMaterial,
	Group,
} from "three";

class ModelController {
	models = {};

	isLoaded = false;
	loadedTime = 0;
	constructor(scene) {
		this.modelLoader = new ModelLoader();
		// this.uniforms =
		this.scene = scene;
	}

	update = () => {
		if (!this.isLoaded) return;
		this.models["tiwai_i"].material.uniforms.opacity.value = clamp(
			(window.performance.now() - this.loadedTime) * 0.001,
			0,
			1
		);
	};

	loaded = () => {
		this.isLoaded = true;
		this.loadedTime = window.performance.now();
	};

	addModel = ({
		name,
		model: src,
		size = 1,
		far = false,
		near = false,
		assemble = true,
	}) => {
		// ply models

		return new Promise((resolve) => {
			this.modelLoader.load(src).then((model) => {
				const newGeo = new BufferGeometry();
				const color = [];
				const position = [];
				const position2 = [];
				const sizes = [];
				const randoms = [];
				const { count, itemSize, array } = model.attributes.position;
				const { array: cArray } = model.attributes.color;

				let mat = new ShaderMaterial({
					uniforms: {
						time: {
							value: window.performance.now() * 0.001,
						},
						fogN: { type: "f", value: near || this.scene.fog.near },
						fogF: { type: "f", value: far || this.scene.fog.far },
						fogColor: { type: "c", value: this.scene.fog.color },
						fogNear: { type: "f", value: near || this.scene.fog.near },
						fogFar: { type: "f", value: far || this.scene.fog.far },
						assemble: { type: "f", value: assemble ? 1 : 0 },
						opacity: { type: "f", value: 1 },
					},
					vertexShader,
					fragmentShader,
					transparent: true,
					depthWrite: name !== "alley",
					depthTest: name !== "alley",
					fog: true,
					// blending: AdditiveBlending,
				});

				if (name == "tiwai_i") {
					mat = new ShaderMaterial({
						uniforms: {
							time: {
								value: window.performance.now() * 0.001,
							},
							fogN: { type: "f", value: near || this.scene.fog.near },
							fogF: { type: "f", value: far || this.scene.fog.far },
							fogColor: { type: "c", value: this.scene.fog.color },
							fogNear: { type: "f", value: near || this.scene.fog.near },
							fogFar: { type: "f", value: far || this.scene.fog.far },
							assemble: { type: "f", value: 0 },
							opacity: { type: "f", value: 0 },
						},
						vertexShader: driftVert,
						fragmentShader,
						transparent: true,
						fog: true,

						// blending: AdditiveBlending,
					});
				}

				for (let i = 0; i < count * itemSize; i += 3) {
					let x, y, z;
					x = array[i];
					y = array[i + 1];
					z = array[i + 2];

					const r = cArray[i];
					const g = cArray[i + 1];
					const b = cArray[i + 2];

					// if (name == "tiwai_i" && Math.random() > 0.98)
					// 	console.log(r + "," + g + "," + b);
					color.push(r, g, b);
					position.push(x, y, z);
					randoms.push(Math.random(), Math.random(), Math.random());

					const rx = x + (Math.random() - 0.5) * 4;
					const ry = y + (Math.random() - 0.5) * 4;
					const rz = z + (Math.random() - 0.5) * 4;

					position2.push(rx, ry, rz);

					const s = Math.random() * 6 + 5;
					sizes.push(s * size);
				}

				newGeo.setAttribute(
					"random",
					new BufferAttribute(new Float32Array(randoms), 3)
				);

				newGeo.setAttribute(
					"position",
					new BufferAttribute(new Float32Array(position), 3)
				);

				newGeo.setAttribute(
					"position2",
					new BufferAttribute(new Float32Array(position2), 3)
				);
				newGeo.setAttribute(
					"color",
					new BufferAttribute(new Float32Array(color), 3)
				);
				newGeo.setAttribute(
					"size",
					new BufferAttribute(new Float32Array(sizes), 1)
				);

				const points = new Points(newGeo, mat);
				const pointGroup = new Group();

				// points.frustumCulled = false;

				pointGroup.add(points);

				// if (name == "tiwai_i") {
				// 	pointGroup.frustumCulled = false;
				// 	points.frustumCulled = false;
				// 	points.matrixWorldNeedsUpdate = true;
				// 	points.geometry.verticesNeedUpdate = true;
				// 	points.geometry.computeBoundingSphere();
				// 	console.log(points);
				// }

				this.models[name] = {
					points: pointGroup,
					material: mat,
				};

				resolve(pointGroup);
			});
		});
	};
}

export default ModelController;
