class LoadManager {
	total = 0;
	count = 0;

	progressFn = () => {};
	loadedFn = () => {};

	constuctor() {}

	addItemCount = (n = 0) => {
		this.total += n;
	};

	itemLoaded = () => {
		this.count++;

		const prog = (1 / this.total) * this.count;

		this.progressFn(prog);

		if (this.count == this.total) this.loadedFn();
	};

	onProgress(fn) {
		this.progressFn = fn;
	}

	onLoaded = (fn) => {
		this.loadedFn = fn;
	};
}

export default LoadManager;
