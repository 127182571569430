import {
	WebGL1Renderer,
	PerspectiveCamera,
	Scene,
	Object3D,
	Color,
	Fog,
	Vector2,
	Vector3,
	Group,
	WebGLRenderTarget,
} from "three";

import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass.js";
import { BokehPass } from "three/examples/jsm/postprocessing/BokehPass.js";
import { GUI } from "three/examples/jsm/libs/dat.gui.module.js";

import tiwai_i from "../../../webgl/assets/models/p1/tiwai.ply";
import rexFloss_i from "../../../webgl/assets/models/p1/rex-floss.ply";
import rowan_i from "../../../webgl/assets/models/p1/rowan.ply";

import train from "../../../webgl/assets/models/p2/train-s.ply";

import stairs from "../../../webgl/assets/models/p3/stairs.ply";
import dude from "../../../webgl/assets/models/p3/dude.ply";
import jack from "../../../webgl/assets/models/p3/jack.ply";
import jessFloss from "../../../webgl/assets/models/p3/jess-floss.ply";
import lily from "../../../webgl/assets/models/p3/lily.ply";
import man from "../../../webgl/assets/models/p3/man.ply";
import mary from "../../../webgl/assets/models/p3/mary.ply";
import suzie from "../../../webgl/assets/models/p3/susie.ply";
import tiwai from "../../../webgl/assets/models/p3/tiwai.ply";
import suit from "../../../webgl/assets/models/p3/suit.ply";

import alley from "../../../webgl/assets/models/p4/alley-s.ply";
import hands from "../../../webgl/assets/models/p5/hands.ply";

import CameraController from "../../../webgl/scripts/CameraController";
import ModelController from "../../../webgl/scripts/ModelController";
import Conclusion from "../../../webgl/scripts/Conclusion";

import LoadManager from "../../../webgl/scripts/LoadManager";
import LoadPoints from "../../../webgl/scripts/LoadPoints";

import { clamp, relativeProgress as rp } from "../../../util/math";

import { disablePageScroll, enablePageScroll } from "scroll-lock";

import plainVert from "../../../webgl/shaders/plain.vert";
import splitFrag from "../../../webgl/shaders/split.frag";

import blurFrag from "../../../webgl/shaders/blur.frag";

class LidarManager {
	vp = {
		width: window.innerWidth,
		height: window.innerHeight,
	};
	mouse = { x: 0, y: 0, animX: 0, animY: 0 };
	scroll = { x: 0, y: 0 };
	initialCameraPositions = { x: 0, y: 0, z: 5 };
	initialCameraRotations = { x: 0, y: 0, z: 0 };

	timeDelta = 0;
	lastTime = 0;
	currentTime = 0;
	scrollProgress = 0;
	resizeTimeout = null;

	importedModels = [
		{
			name: "tiwai_i",
			model: tiwai_i,
			far: 25,
			size: 0.6,
			assemble: false,
		},
		{
			name: "rexFloss_i",
			model: rexFloss_i,
			far: 20,
			size: 0.6,
			assemble: false,
		},
		{
			name: "rowan_i",
			model: rowan_i,
			far: 25,
			size: 0.6,
			assemble: false,
		},
		{
			name: "train",
			model: train,
			size: 2,
			far: 35,
			assemble: false,
		},
		{
			name: "stairs",
			model: stairs,
		},

		{
			name: "dude",
			model: dude,
		},
		{
			name: "jessFloss",
			model: jessFloss,
		},
		{
			name: "lily",
			model: lily,
		},
		{
			name: "man",
			model: man,
		},
		{
			name: "mary",
			model: mary,
		},
		{
			name: "jack",
			model: jack,
		},

		{
			name: "suzie",
			model: suzie,
		},
		{
			name: "tiwai",
			model: tiwai,
		},
		{
			name: "suit",
			model: suit,
		},

		{
			name: "alley",
			model: alley,
			size: 5,
			far: 25,
			near: 3,
		},
		{
			name: "hands",
			model: hands,
			size: 3,
		},
	];

	constructor({ canvas, pageLayout, onLoad = () => {} }) {
		// setTimeout(disablePageScroll(document.body), 1000);

		this.pageLayout = pageLayout;
		this.canvas = canvas;
		this.onLoad = onLoad;

		this.renderer = new WebGL1Renderer({
			canvas: this.canvas,
			// antialias: true,
			alpha: true,
			performance: "high-performance",
			// logarithmicDepthBuffer: true,
		});

		this.camera = new PerspectiveCamera(
			75,
			window.innerWidth / window.innerHeight,
			0.01,
			50
		);
		this.camera.rotation.x = -Math.PI * 0.5;
		this.scene = new Scene();
		this.pointModels = new Group();

		this.currentChapter = this.pageLayout.chapters[0];

		const cameraWrap = new Object3D();
		cameraWrap.add(this.camera);
		this.scene.add(cameraWrap);
		this.scene.add(this.pointModels);

		this.pointModels.visible = false;

		this.loadManager = new LoadManager();
		this.loadPoints = new LoadPoints(this.scene);

		cameraWrap.add(this.loadPoints.points);

		this.cameraController = new CameraController(cameraWrap);

		this.loadManager.addItemCount(this.importedModels.length + 1);
		this.loadManager.onProgress((prog) => {
			// console.log("load progress : " + prog);

			this.loadPoints.setLoadVal(prog);
		});

		this.loadManager.onLoaded(() => {
			this.cameraController.activate();
			this.loadPoints.loaded();

			setTimeout(() => {
				this.pointModels.visible = true;
				this.modelController.loaded();
				if (this.onLoad) {
					this.onLoad();
				}
			}, 1000);
		});

		this.cameraController.init().then(() => {
			this.loadManager.itemLoaded();
		});

		this.scene.background = new Color(0xf4f4f4);
		this.scene.fog = new Fog(0xf4f4f4, 3, 15);

		this.modelController = new ModelController(this.scene);
		this.conclusion = new Conclusion(this.scene);

		this.importedModels.forEach((importedModel) => {
			this.modelController.addModel(importedModel).then((points) => {
				this.loadManager.itemLoaded();
				this.pointModels.add(points);
			});
		});

		this.shaderPasses();

		this.init();

		this.configControls();
	}

	shaderPasses = () => {
		const renderScene = new RenderPass(this.scene, this.camera);

		// const effectController = {
		// 	focus: 5.0,
		// 	aperture: 5,
		// 	maxblur: 0.01,

		// 	width: window.innerWidth,
		// 	height: window.innerHeight,
		// };

		// this.bokehPass = new BokehPass(this.scene, this.camera, effectController);

		this.composer2 = new EffectComposer(this.renderer);
		this.composer2.renderToScreen = true;
		this.composer2.addPass(renderScene);

		this.composer1 = new EffectComposer(this.renderer);
		this.composer1.renderToScreen = true;
		this.composer1.addPass(renderScene);

		// this.blurTarget = new WebGLRenderTarget(
		// 	window.innerWidth / 5,
		// 	window.innerHeight / 5
		// );

		// this.composer2.addPass(this.bokehPass);

		this.bloomParams = {
			exposure: 1.3,
			bloomStrength: 1.4,
			bloomThreshold: 0,
			bloomRadius: 1,
		};

		const bloomPass = new UnrealBloomPass(
			new Vector2(window.innerWidth, window.innerHeight),
			1.5,
			0.4,
			0.85
		);

		bloomPass.exposure = this.bloomParams.exposure;
		bloomPass.threshold = this.bloomParams.bloomThreshold;
		bloomPass.strength = this.bloomParams.bloomStrength;
		bloomPass.radius = this.bloomParams.bloomRadius;

		const splitPass = new ShaderPass(
			{
				uniforms: {
					time: {
						value: 0,
					},
					res: {
						value: [window.innerWidth, window.innerHeight],
					},
					tDiffuse: {
						value: null,
					},
					intensity: {
						value: 1,
					},
					darken: {
						value: 1,
					},
					cameraNear: { value: 0.001 },
					cameraFar: { value: 5 },
				},
				vertexShader: plainVert,
				fragmentShader: splitFrag,
			},
			"tDiffuse"
		);

		this.splitPass = splitPass;
		this.composer2.addPass(bloomPass);
		this.composer2.addPass(splitPass);

		this.blurPass = new ShaderPass(
			{
				uniforms: {
					time: {
						value: 0,
					},
					res: {
						value: [window.innerWidth, window.innerHeight],
					},
					tDiffuse: {
						value: null,
					},
				},
				vertexShader: plainVert,
				fragmentShader: blurFrag,
			},
			"tDiffuse"
		);

		this.composer1.addPass(this.blurPass);
	};

	configControls = () => {
		const effectController = {
			focus: 500.0,
			aperture: 5,
			maxblur: 0.01,
		};

		const matChanger = () => {
			this.bokehPass.uniforms["focus"].value = effectController.focus;
			this.bokehPass.uniforms["aperture"].value =
				effectController.aperture * 0.00001;
			this.bokehPass.uniforms["maxblur"].value = effectController.maxblur;
		};
	};

	init = () => {
		this.sizing();
		this.events();
		this.onFrame();

		this.onScroll();
	};

	events = () => {
		window.addEventListener("resize", this.resize);
		window.addEventListener("mousemove", this.mousemove);
		window.addEventListener("scroll", this.onScroll);
	};

	sizing = () => {
		const { width, height } = this.vp;

		this.camera.aspect = width / height;
		this.camera.updateProjectionMatrix();

		this.composer1.setSize(width, height);
		this.composer2.setSize(width, height);

		this.splitPass.uniforms.res.value = [window.innerWidth, window.innerHeight];

		this.renderer.setPixelRatio(1);
		this.renderer.setSize(width, height);
	};

	mousemove = (e) => {
		const { clientX: x, clientY: y } = e;
		const { width: w, height: h } = this.vp;

		this.mouse.x = (x / w) * 2 - 1;
		this.mouse.y = -((y / h) * 2 - 1);
	};

	onScroll = (e) => {
		this.scrolling = true;
		const { top } = document.body.getBoundingClientRect();

		this.scrollProgress = this.updateProgress(top);

		this.updateBG(top);

		setTimeout(() => {
			this.scrolling = false;
		}, 500);
	};

	updateProgress = (top) => {
		const { chapters } = this.pageLayout;
		const { innerHeight: wh } = window;
		let current = 0;

		chapters.forEach((chapter, i) => {
			const t = chapter.start + top;
			if (t < 0) current = i;
		});

		const chapter = chapters[current];
		const d = chapter.end - chapter.start;
		const t = chapter.start + top;
		const v = clamp(-t / d, 0, 1);

		return current + v;
	};

	lerpColor = (a, b, amount) => {
		const ar = a >> 16,
			ag = (a >> 8) & 0xff,
			ab = a & 0xff,
			br = b >> 16,
			bg = (b >> 8) & 0xff,
			bb = b & 0xff,
			rr = ar + amount * (br - ar),
			rg = ag + amount * (bg - ag),
			rb = ab + amount * (bb - ab);

		return (rr << 16) + (rg << 8) + (rb | 0);
	};

	updateBG = (top) => {
		const { innerHeight: wh } = window;
		const bgs = this.backgroundColors;

		if (!bgs) return;
		let current = 0;

		bgs.forEach((bg, i) => {
			const t = bg.start + top;
			if (t < wh) current = i;
		});

		const bg = bgs[current];

		const d = bg.end - bg.start;
		const t = bg.start + top;

		const v = clamp((wh - t) / d, 0, 1);

		const c1 = parseInt(Number("0x" + bg.from.replace("#", "")), 10);
		const c2 = parseInt(Number("0x" + bg.to.replace("#", "")), 10);

		const color = new Color(this.lerpColor(c1, c2, v));

		this.scene.fog.color = color;
		this.scene.background = color;
	};

	resize = () => {
		const { innerWidth: width, innerHeight: height } = window;
		this.vp = { width, height };

		clearTimeout(this.resizeTimeout);

		this.resizeTimeout = setTimeout(() => {
			this.sizing();
		}, 200);
	};

	onFrame = () => {
		this.render();
		window.requestAnimationFrame(this.onFrame);
	};

	render = () => {
		if (this.cameraController.progress == 0)
			this.cameraController.progress = this.scrollProgress;

		const p = this.cameraController.update(
			this.scrollProgress,
			this.pageLayout.chapters.length
		);

		this.loadPoints.update();

		this.modelController.update();

		if (this.modelController.models["tiwai_i"]) {
			this.modelController.models["tiwai_i"].material.uniforms.time.value =
				window.performance.now() * 0.001;
			this.modelController.models["tiwai_i"].material.uniforms.assemble.value =
				rp(p, 0, 0.1);
		}

		if (p < 5) {
			this.blurPass.uniforms.time.value = window.performance.now() * 0.001;
			// this.renderer.setRenderTarget(this.blurTarget);
			this.renderer.render(this.scene, this.camera);
			// this.renderer.setRenderTarget(null);
			// this.composer1.render();
		} else {
			this.splitPass.uniforms.time.value = window.performance.now() * 0.001;
			this.composer2.render();
		}

		if (this.modelController.models["train"])
			this.modelController.models["train"].points.visible = p <= 3;

		this.conclusion.update(p);

		const camPos = new Vector3(p);

		this.camera.getWorldPosition(camPos);

		// console.log(camPos);
	};

	cleanup = () => {
		//cleanup resources (textures, meshes etc) and eventListeners
	};
}
export default LidarManager;
