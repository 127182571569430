import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { PLYLoader } from "three/examples/jsm/loaders/PLYLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { withPrefix } from "gatsby-link";

export default class ModelLoader {
	gltfLoader = new GLTFLoader();
	plyLoader = new PLYLoader();

	_loaders = {
		ply: null,
		gltf: null,
	};

	getLoader = (model) => {
		const ext = model.split(".")[1];
		if (ext === "ply")
			return (this._loaders.ply = this._loaders.ply || new PLYLoader());
		else if (ext.match(/(gltf|glb)/gm)) {
			if (this._loaders.gltf) return this._loaders.gltf;

			const dracoLoader = new DRACOLoader();
			dracoLoader.setDecoderPath(withPrefix("/draco/"));
			this._loaders.gltf = new GLTFLoader();
			this._loaders.gltf.setDRACOLoader(dracoLoader);

			return this._loaders.gltf;
		}
	};

	load = (src, onProgress = () => {}) => {
		const loader = this.getLoader(src);

		return new Promise((resolve, reject) => {
			loader.load(src, resolve, onProgress);
		});
	};
}
