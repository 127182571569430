import {
	PlaneBufferGeometry,
	Points,
	ShaderMaterial,
	BufferGeometry,
	BufferAttribute,
} from "three";

import vertexShader from "../shaders/conclusion.vert";
import fragmentShader from "../shaders/conclusion.frag";

import { relativeProgress as rp, clamp } from "../../util/math";

class Conclusion {
	constructor(scene) {
		const near = 1;
		const far = 10;

		this.uniforms = {
			time: {
				type: "f",
				value: 0,
			},
			progress: {
				type: "f",
				value: 0,
			},
			fogN: { type: "f", value: near },
			fogF: { type: "f", value: far },
			fogColor: { type: "c", value: scene.fog.color },
			fogNear: { type: "f", value: near },
			fogFar: { type: "f", value: far },
		};

		const geo = new PlaneBufferGeometry(50, 100, 1000, 2000);
		const bufGeo = new BufferGeometry();

		bufGeo.addAttribute("position", geo.attributes.position, 3);

		const mat = new ShaderMaterial({
			uniforms: this.uniforms,
			vertexShader,
			fragmentShader,
			transparent: true,
			// depthWrite: false,
			// depthTest: false,
			fog: true,
		});

		this.points = new Points(bufGeo, mat);

		const x = 111.85;
		const z = -234.423;
		const y = 17.8913;

		this.points.position.set(x, y, z);

		this.points.rotation.x = Math.PI * 0.5;
		this.points.rotation.y = 1;
		this.points.rotation.order = "YXZ";

		this.points.scale.set(4, 4, 1);

		this.points.updateMatrix();

		this.points.geometry.applyMatrix(this.points.matrix);

		this.points.position.set(0, 0, 0);
		this.points.rotation.set(0, 0, 0);
		this.points.scale.set(1, 1, 1);
		this.points.updateMatrix();

		scene.add(this.points);
	}

	update = (p) => {
		this.uniforms.progress.value = clamp(rp(p, 8, 9), 0, 0.2);
		this.uniforms.time.value = window.performance.now() * 0.001;
	};
}

export default Conclusion;
