import ModelLoader from "./ModelLoader";
import model from "../assets/models/camera5.glb";
import { AnimationMixer } from "three";
import { relativeProgress as rp } from "../../util/math";

class CameraController {
	progress = 0;

	mapping = {
		introLead: {
			keys: {
				start: 0,
				end: 100,
			},
			progress: {
				start: 0,
				end: 1,
			},
		},
		intro: {
			keys: {
				start: 100,
				end: 300,
			},
			progress: {
				start: 1,
				end: 1.85,
			},
		},
		gap: {
			keys: {
				start: 300,
				end: 450,
			},
			progress: {
				start: 1.85,
				end: 1.851,
			},
		},
		trainIntro: {
			keys: {
				start: 450,
				end: 500,
			},
			progress: {
				start: 1.851,
				end: 2.1,
			},
		},
		train: {
			keys: {
				start: 500,
				end: 550,
			},
			progress: {
				start: 2.1,
				end: 2.9,
			},
		},
		stairsIntroGap: {
			keys: {
				start: 550,
				end: 600,
			},
			progress: {
				start: 2.9,
				end: 2.91,
			},
		},
		stairsIntro: {
			keys: {
				start: 600,
				end: 720,
			},
			progress: {
				start: 2.91,
				end: 3.3,
			},
		},
		stairs: {
			keys: {
				start: 720,
				end: 950,
			},
			progress: {
				start: 3.3,
				end: 5,
			},
		},
		blank: {
			keys: {
				start: 950,
				end: 980,
			},
			progress: {
				start: 5,
				end: 5.01,
			},
		},
		alley: {
			keys: {
				start: 980,
				end: 1030,
			},
			progress: {
				start: 5.01,
				end: 5.8,
			},
		},

		hands: {
			keys: {
				start: 1030,
				end: 1060,
			},
			progress: {
				start: 5.8,
				end: 7,
			},
		},

		conclusion: {
			keys: {
				start: 1060,
				end: 1080,
			},
			progress: {
				start: 7,
				end: 8,
			},
		},
		conclusion2: {
			keys: {
				start: 1080,
				end: 1090,
			},
			progress: {
				start: 8,
				end: 9,
			},
		},
	};

	keyframes = 1100;
	mapped = [];
	canRun = false;

	constructor(camera) {
		this.camera = camera;
	}

	init = () => {
		return new Promise((resolve) => {
			const loader = new ModelLoader();

			loader.load(model).then((gltf) => {
				// console.log(gltf.animations);
				this.clip = gltf.animations[0];
				this.mixer = new AnimationMixer(this.camera);
				this.action = this.mixer.clipAction(this.clip);
				this.action.play();
				this.mixer.update(0.001);
				this.mixer.setTime(0.001);
				// console.log("duration", this.clip.duration);
			});

			for (let prop in this.mapping) {
				const { keys, progress } = this.mapping[prop];
				const keysDuration = (keys.end - keys.start) / this.keyframes;

				this.mapped.push({ keysDuration, progress });
			}

			resolve();
		});
	};

	activate = () => {
		this.canRun = true;
	};

	update(progress = 0, length = 0) {
		// console.log(progress);
		if (!this.canRun) return 0;
		this.progress += (progress - this.progress) * 0.1;

		let animProg = 0;

		this.mapped.forEach(({ keysDuration, progress }) => {
			animProg +=
				keysDuration * rp(this.progress, progress.start, progress.end);
		});

		// console.log(animProg * (this.keyframes / this.clip.duration));

		if (this.mixer) this.mixer.setTime(this.clip.duration * animProg);

		return this.progress;
	}
}

export default CameraController;
