import {
	BufferGeometry,
	BufferAttribute,
	Points,
	ShaderMaterial,
	Group,
} from "three";

import fragmentShader from "../shaders/loading.frag";
import vertexShader from "../shaders/loading.vert";
import { clamp, relativeProgress as rp } from "../../util/math";

class LoadPoints {
	loadVal = 0;
	isLoaded = false;
	currentLoadVal = 0;
	colorOptions = [
		[0.01568627543747425, 0.0117647061124444, 0.0235294122248888],
		[0.2078431397676468, 0.1725490242242813, 0.1568627506494522],
		[0.16862745583057404, 0.18039216101169586, 0.21176470816135406],
		[0.7529411911964417, 0.6000000238418579, 0.5058823823928833],
		[0.1568627506494522, 0.09019608050584793, 0.08235294371843338],
		[0.32549020648002625, 0.22745098173618317, 0.21960784494876862],
		[0.3803921639919281, 0.3529411852359772, 0.2666666805744171],
		[0.6666666865348816, 0.6705882549285889, 0.7058823704719543],
		[0.0117647061124444, 0.0313725508749485, 0.027450980618596077],
		[0.18431372940540314, 0.1725490242242813, 0.20392157137393951],
		[0.20000000298023224, 0.13333334028720856, 0.15294118225574493],
		[0.18431372940540314, 0.1764705926179886, 0.20392157137393951],
		[0.1411764770746231, 0.0941176488995552, 0.07450980693101883],
		[0.08627451211214066, 0.054901961237192154, 0.08627451211214066],
		[0.23529411852359772, 0.22745098173618317, 0.2235294133424759],
		[0.10196078568696976, 0.10196078568696976, 0.10588235408067703],
		[0.7647058963775635, 0.5372549295425415, 0.5098039507865906],
		[0.6823529601097107, 0.49803921580314636, 0.4000000059604645],
		[0.545098066329956, 0.3607843220233917, 0.38823530077934265],
		[0.8078431487083435, 0.7019608020782471, 0.7333333492279053],
		[0.6196078658103943, 0.529411792755127, 0.5960784554481506],
		[0.3607843220233917, 0.3333333432674408, 0.2549019753932953],
		[0.10588235408067703, 0.10196078568696976, 0.12156862765550613],
		[0.09803921729326248, 0.08627451211214066, 0.12156862765550613],
		[0.10588235408067703, 0.13725490868091583, 0.16078431904315948],
		[0.3803921639919281, 0.27450981736183167, 0.22745098173618317],
		[0.16470588743686676, 0.12156862765550613, 0.10980392247438431],
		[0.13333334028720856, 0.10588235408067703, 0.08627451211214066],
		[0.43921568989753723, 0.4117647111415863, 0.3333333432674408],
		[0.729411780834198, 0.4431372582912445, 0.4313725531101227],
		[0.1725490242242813, 0.10196078568696976, 0.10588235408067703],
		[0.7490196228027344, 0.6000000238418579, 0.545098066329956],
		[0.13725490868091583, 0.08627451211214066, 0.08627451211214066],
		[0.16078431904315948, 0.14509804546833038, 0.18431372940540314],
		[0.4901960790157318, 0.4000000059604645, 0.3960784375667572],
		[0.5843137502670288, 0.3333333432674408, 0.250980406999588],
		[0.5568627715110779, 0.3921568691730499, 0.2862745225429535],
		[0.20000000298023224, 0.16078431904315948, 0.21568627655506134],
		[0.4470588266849518, 0.30588236451148987, 0.30588236451148987],
		[0.14509804546833038, 0.11764705926179886, 0.11372549086809158],
		[0.1764705926179886, 0.16862745583057404, 0.1882352977991104],
		[0.08627451211214066, 0.0941176488995552, 0.10196078568696976],
		[0.364705890417099, 0.18039216101169586, 0.12941177189350128],
		[0.3137255012989044, 0.27843138575553894, 0.250980406999588],
		[0.10980392247438431, 0.04313725605607033, 0.04313725605607033],
		[0.772549033164978, 0.6039215922355652, 0.4941176474094391],
		[0.30588236451148987, 0.27450981736183167, 0.24313725531101227],
		[0.4000000059604645, 0.30980393290519714, 0.20392157137393951],
		[0.7960784435272217, 0.4745098054409027, 0.48627451062202454],
		[0.0313725508749485, 0.03529411926865578, 0.03921568766236305],
		[0.5686274766921997, 0.3803921639919281, 0.3450980484485626],
		[0.35686275362968445, 0.33725491166114807, 0.24313725531101227],
	];

	constructor() {
		const pointCount = 200;
		const pointSize = 20;
		const positions = [];
		const colors = [];
		const randoms = [];

		for (let i = 0; i < pointCount * 3; i += 3) {
			randoms.push(Math.random(), Math.random(), Math.random());
			positions.push(Math.random(), Math.random(), Math.random());

			const [r, g, b] =
				this.colorOptions[Math.floor(Math.random() * this.colorOptions.length)];

			colors.push(r, g, b);
		}

		// console.log(colors);

		this.uniforms = {
			time: {
				value: 0,
			},
			loadVal: {
				value: 0,
			},
			loadedProg: {
				value: 0,
				type: "f",
			},
			size: {
				value: pointSize,
			},
			PI: {
				value: Math.PI,
			},
		};

		this.material = new ShaderMaterial({
			uniforms: this.uniforms,
			vertexShader,
			fragmentShader,
			transparent: true,
			// depthWrite: false,
			// depthTest: false,
			// fog: true,
			//` blending: AdditiveBlending,
		});

		this.geomtry = new BufferGeometry();
		this.geomtry.setAttribute(
			"position",
			new BufferAttribute(new Float32Array(positions), 3)
		);

		this.geomtry.setAttribute(
			"color",
			new BufferAttribute(new Float32Array(colors), 3)
		);

		this.geomtry.setAttribute(
			"random",
			new BufferAttribute(new Float32Array(randoms), 3)
		);

		this.points = new Points(this.geomtry, this.material);
		this.points.position.y = -10;
		this.points.rotation.x = -Math.PI * 0.5;

		randoms.push(Math.random(), Math.random(), Math.random());
	}

	setLoadVal = (val) => {
		this.loadVal = val;
	};

	loaded = () => {
		this.isLoaded = true;
		this.loadedTime = window.performance.now();

		setTimeout(() => {
			this.points.visible = false;
		}, 2000);
	};

	update = () => {
		// this.

		this.currentLoadVal += (this.loadVal - this.currentLoadVal) * 0.03;

		// console.log(this.currentLoadVal);

		if (this.isLoaded)
			this.uniforms.loadedProg.value = clamp(
				(window.performance.now() - this.loadedTime) * 0.001,
				0,
				1
			);

		this.uniforms.loadVal.value = this.currentLoadVal;
		this.uniforms.time.value = window.performance.now() * 0.001;
	};
}

export default LoadPoints;
