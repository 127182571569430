import { useCallback, useEffect, useRef } from "react";
import { usePageLayout } from "../contexts/PageLayout";
import { useGetResizer } from "../contexts/Resizer";

export function useChapterLayout({ data, index }) {
	let ref = useRef();
	const pageLayout = usePageLayout();
	const area = useGetResizer();
	useEffect(() => {
		if (ref.current) {
			const rect = ref.current.getBoundingClientRect();
			const scrollY = window.scrollY;
			let chapter = {
				key: data.keyId,
				start: rect.top + scrollY,
				end: rect.bottom + scrollY,
				index: index,
				theme: data.theme,
			};
			pageLayout.addChapter(index, chapter);
		}
	}, [area, data, index]);
	return useCallback(
		(node) => {
			if (node && node !== ref.current) {
				ref.current = node;
				const rect = ref.current.getBoundingClientRect();
				const scrollY = window.scrollY;
				let chapter = {
					key: data.keyId,
					start: rect.top + scrollY,
					end: rect.bottom + scrollY,
					index: index,
					theme: data.theme,
				};
				pageLayout.addChapter(index, chapter);
			}
		},
		[data, index, pageLayout]
	);
}
