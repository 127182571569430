export function generateQuoteNumbers(nodes) {
	let currentQuote = 1;
	const countQuotes = (obj) => {
		for (const [key, value] of Object.entries(obj)) {
			if (value && value.component === "Structure/BlockQuote") {
				value.quoteIndex = currentQuote;
				currentQuote++;
			}
			if (typeof value === "object" && !!value) {
				countQuotes(value);
			}
		}
	};
	countQuotes(nodes);

	return nodes;
}
