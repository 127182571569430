import React, { useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { container, screen } from "../../../styles/mixins";
import { abcMaxiPlusMono } from "../../../styles/typography-act-2";
import { fluid } from "../../../styles/functions";
import { useShuffle } from "../../../util/useShuffle";
import { css } from "@emotion/react";
const Container = styled.div`
	${container};
	grid-row-gap: 40px;
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	height: 100vh;
	align-content: center;
	color: #201e1f;
	z-index: 3;
`;
const position = css`
	grid-column: 3 / -3;
	@media ${screen("md")} {
		grid-column: 5 / -5;
	}

	@media ${screen("ml")} {
		grid-column: 9 / -9;
	}

	@media ${screen("lg")} {
		grid-column: 13 / -13;
	}
`;

const QuoteEl = styled.div`
	${position};

	${abcMaxiPlusMono};
	font-size: ${fluid(26, 50)};
	font-weight: 400;
	line-height: 3;
	grid-row: 2;
`;
const QuoteLine = styled.div`
	display: flex;
	justify-content: space-between;
`;

const MetaData = styled.div`
	${abcMaxiPlusMono};
	font-weight: 400;
	grid-row: 1;
	${position};
	font-size: 10px;
`;

const MetaData2 = styled.div`
	${abcMaxiPlusMono};
	font-weight: 400;
	grid-row: 3;
	${position};
	font-size: 10px;
`;
const MetaData3 = styled.div`
	${abcMaxiPlusMono};
	font-weight: 400;
	${position};
	grid-row: 3;
	font-size: 10px;
	text-align: right;
`;

const hidden = css`
	visibility: hidden;
`;
const Quote = ({ show }) => {
	const [quoteRef, startQuote, stopQuote] = useShuffle({
		repeat: true,
		triggerWhenInView: false,
	});
	const [metadata1Ref, startMeta1, stopMeta1] = useShuffle({
		repeat: true,
		triggerWhenInView: false,
	});
	const [metadata2Ref, startMeta2, stopMeta2] = useShuffle({
		repeat: true,
		triggerWhenInView: false,
	});
	const [metadata3Ref, startMeta3, stopMeta3] = useShuffle({
		repeat: true,
		triggerWhenInView: false,
	});
	const hasShown = useRef(false);

	useEffect(() => {
		if (show) {
			startQuote();
			startMeta1();
			startMeta2();
			startMeta3();
			hasShown.current = true;
		} else {
			if (hasShown.current) {
				stopQuote(true);
				stopMeta1(true);
				stopMeta2(true);
				stopMeta3(true);
			}
		}
	}, [show]);
	return (
		<Container>
			<MetaData css={hidden} ref={metadata1Ref}>
				(OPENING QUOTE)
			</MetaData>
			<QuoteEl ref={quoteRef}>
				<QuoteLine>
					<span css={hidden}>TECHNOLOGY</span>
					<span css={hidden}>IS</span>
					<span css={hidden}>NEITHER</span>
				</QuoteLine>
				<QuoteLine>
					<span css={hidden}>GOOD</span>
					<span css={hidden}>NOR</span>
					<span css={hidden}>BAD</span>
				</QuoteLine>
				<QuoteLine>
					<span css={hidden}>NOR</span>
					<span css={hidden}>NEUTRAL</span>
				</QuoteLine>
			</QuoteEl>
			<MetaData2 css={hidden} ref={metadata2Ref}>
				39 CHARACTERS
			</MetaData2>
			<MetaData3 css={hidden} ref={metadata3Ref}>
				AUTHOR // MELVIN KRANZBERG
			</MetaData3>
		</Container>
	);
};

export default Quote;
