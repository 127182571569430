import React, { useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { container, fontSize, screen } from "../../../styles/mixins";
import { abcMaxiPlusMono } from "../../../styles/typography-act-2";
import { fluid } from "../../../styles/functions";
import { useShuffle } from "../../../util/useShuffle";
import { css } from "@emotion/react";
const Container = styled.div`
	${container};
	grid-row-gap: 40px;
	width: 100%;
	height: 100vh;
	align-content: center;

	position: absolute;
	top: 0;
	left: 0;
	color: #201e1f;
	z-index: 3;
`;
const TitleLine = styled.div`
	display: flex;
	justify-content: space-between;
`;
const Title = styled.div`
	grid-column: 3 / -3;
	grid-row: 2;
	${abcMaxiPlusMono};
	font-size: ${fluid(32, 200)};
	text-align: justify;
	font-weight: 400;
	line-height: 0.94;
	font-feature-settings: "ss06" on, "ss07" on, "ss05" on;
`;

const MetaData = styled.div`
	${abcMaxiPlusMono};
	font-weight: 400;
	grid-row: 3;
	grid-column: 3 / -3;
	${fontSize(-0.8)};
`;

const MetaData2 = styled.div`
	${abcMaxiPlusMono};
	font-weight: 400;
	grid-row: 3;
	grid-column: 18 / -3;
	@media ${screen("md")} {
		grid-column: 15 / -3;
	}

	@media ${screen("ml")} {
		grid-column: 13 / -3;
	}

	@media ${screen("lg")} {
		grid-column: 9 / -3;
	}

	${fontSize(-0.8)};
`;
const MetaData3 = styled.div`
	${abcMaxiPlusMono};
	font-weight: 400;
	grid-column: 3 / 28;
	@media ${screen("md")} {
		grid-column: 3 / 22;
	}

	@media ${screen("ml")} {
		grid-column: 3 / 18;
	}

	@media ${screen("lg")} {
		grid-column: 3 / 13;
	}
	grid-row: 1;
	${fontSize(-0.8)};
	display: flex;
	justify-content: space-between;
`;
const MetaData4 = styled.div`
	${abcMaxiPlusMono};
	font-weight: 400;
	grid-column: span 20 / -3;
	@media ${screen("md")} {
		grid-column: span 20 / -3;
	}

	@media ${screen("ml")} {
		grid-column: span 20 / -3;
	}

	@media ${screen("lg")} {
		grid-column: span 20 / -3;
	}
	grid-row: 3;
	${fontSize(-0.8)};
	justify-self: end;
`;

const hidden = css`
	visibility: hidden;
`;
const Cover = ({ show }) => {
	const [titleRef, startTitle, stopTitle] = useShuffle({
		repeat: true,
		triggerWhenInView: false,
	});
	const [metadata1Ref, startMeta1, stopMeta1] = useShuffle({
		repeat: true,
		triggerWhenInView: false,
	});
	const [metadata2Ref, startMeta2, stopMeta2] = useShuffle({
		repeat: true,
		triggerWhenInView: false,
	});
	const [metadata3Ref, startMeta3, stopMeta3] = useShuffle({
		repeat: true,
		triggerWhenInView: false,
	});
	const [metadata4Ref, startMeta4, stopMeta4] = useShuffle({
		repeat: true,
		triggerWhenInView: false,
	});
	const hasShown = useRef(false);

	useEffect(() => {
		if (show) {
			startTitle();
			startMeta1();
			startMeta2();
			startMeta3();
			startMeta4();
			hasShown.current = true;
		} else {
			if (hasShown.current) {
				stopTitle(true);
				stopMeta1(true);
				stopMeta2(true);
				stopMeta3(true);
				stopMeta4(true);
			}
		}
	}, [
		show,
		startTitle,
		startMeta1,
		startMeta2,
		startMeta3,
		startMeta4,
		stopTitle,
		stopMeta1,
		stopMeta2,
		stopMeta3,
		stopMeta4,
	]);
	return (
		<Container>
			<MetaData3 ref={metadata3Ref}>
				<span css={hidden}>PT. 02</span>
			</MetaData3>
			<Title ref={titleRef}>
				<TitleLine css={hidden}>ECONOMIC</TitleLine>
				<TitleLine css={hidden}>OPPORTUNITIES</TitleLine>
				<TitleLine>
					<span css={hidden}>FOR</span>
					<span css={hidden}>OUR</span>
					<span css={hidden}>AVATARS</span>
				</TitleLine>
			</Title>
			<MetaData css={hidden} ref={metadata1Ref}>
				WORDS // 11248
			</MetaData>
			<MetaData2 ref={metadata2Ref}>
				<div css={hidden}>CHAPTERS // 08</div>
				<div css={hidden}>SUB CHAPTERS // 23</div>
			</MetaData2>
			<MetaData4 css={hidden} ref={metadata4Ref}>
				SCROLL TO READ
			</MetaData4>
		</Container>
	);
};

export default Cover;
