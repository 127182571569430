import React, { useEffect, useMemo, useRef } from "react";
import styled from "@emotion/styled";
import { graphql } from "gatsby";
import Lidar from "../components/Act2/Lidar/Lidar";
import importDirectory from "../util/importDirectory";
import Seo from "../components/Seo";
import { PageLayout } from "../contexts/PageLayout";
import { useResizer } from "../contexts/Resizer";
import { generateQuoteNumbers } from "../util/generateQuoteNumbers";
import { useUIState } from "../contexts/UIStore";
import PrimaryCover from "../components/Act2/PrimaryCover";
import { Helmet } from "react-helmet";
import shareImage from "../images/share-image-act-2.png";
import { motion } from "framer-motion";

let chapterLayouts = importDirectory(
	require.context(
		"../components/Act2/ChapterLayout/",
		true,
		/[a-zA-Z_-]*\.js$/
	),
	"default"
);

const Container = styled.div`
	//position: relative;
	//z-index: 2;
	//color: #f3eeeb;
	display: flex;
	flex-direction: column;
`;
const EconomicOpportunitiesForOurAvatars = ({
	data: {
		allAct2Yaml: { nodes },
	},
}) => {
	const ref = useRef();
	const uiState = useUIState();
	const setArea = useResizer();
	useEffect(() => {
		uiState.setGrain(false);
		uiState.setLockNav(false);
	}, [uiState]);

	const chapters = useMemo(() => {
		return generateQuoteNumbers(nodes);
	}, [nodes]);
	useEffect(() => {
		let timeout;
		const observer = new ResizeObserver((entries) => {
			for (let entry of entries) {
				clearTimeout(timeout);
				let width, height;
				if (entry.borderBoxSize) {
					const borderBoxSize = Array.isArray(entry.borderBoxSize)
						? entry.borderBoxSize[0]
						: entry.borderBoxSize;

					width = borderBoxSize.inlineSize;
					height = borderBoxSize.blockSize;
				} else {
					width = entry.contentRect.width;
					height = entry.contentRect.height;
				}
				timeout = setTimeout(() => {
					setArea((area) => {
						if (area.width !== width || area.height !== height) {
							return { width, height };
						} else {
							return area;
						}
					});
					uiState.setWindowDimensions(window.innerHeight, window.innerWidth);
				}, 150);
			}
		});
		observer.observe(ref.current);
		return () => {
			observer.disconnect();
		};
	}, [uiState, setArea]);
	return (
		<motion.div ref={ref}>
			<Seo
				title="Economic opportunities for our avatars"
				image={shareImage}
				description={
					"How new digital economies are transforming wealth and mobility"
				}
			/>
			<PageLayout>
				<Helmet bodyAttributes={{ class: "no-grain" }} />
				<Lidar />
				<Container>
					<PrimaryCover />
					{chapters.map((chapter, i) => {
						let Component = chapterLayouts[chapter.component];
						return (
							<Component key={chapter.keyId} data={chapter} index={i + 1} />
						);
					})}
				</Container>
			</PageLayout>
		</motion.div>
	);
};

export const pageQuery = graphql`
	query ActTwo {
		allAct2Yaml(sort: { fields: order, order: ASC }) {
			nodes {
				title
				menuTitle
				chapterTitle
				meta
				titleLayout
				decoration
				words
				order
				keyId
				subtitle
				component
				spacingBottom
				theme {
					background
					foreground
				}
				sections {
					theme {
						alignment
						background
						foreground
					}
					component
					labels
					body {
						component
						content
						quote
						size
						attribution
						cardContent {
							title
							foreground
							background
							body {
								content
								component
							}
						}
						title
						meta
						link
						subtitle
						graphRows {
							name
							key
							color
						}
						data {
							childrenCostOfEducationCsv {
								Date
								Education
								Health_care
								House_price
								Income
							}
						}
						decoration
						revolutions {
							name
							shortTitle
							theme {
								background
								foreground
								cardBackground
								cardForeground
								copy
							}
							content
							revolution {
								id
								endDate
								startDate
								title
								inventions {
									category
									date
									description
									name
									rawDate
									source
								}
							}
						}
						theme {
							background
							foreground
							cardForeground
							cardBackground
						}
						sections {
							body {
								component
								content
								quote
								size
							}
							htmlTitle
							title
							cardImage {
								publicURL
							}
						}
					}
				}
			}
		}
	}
`;

export default EconomicOpportunitiesForOurAvatars;
