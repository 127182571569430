import React, { useCallback, useEffect, useRef } from "react";
import importDirectory from "../../../util/importDirectory";
import Title from "../Structure/Title";
import styled from "@emotion/styled";
import { useChapterLayout } from "../../../util/useChapterLayout";
import { ThemeProvider } from "@emotion/react";
import { defaultTheme } from "../themes";
import { useUIState } from "../../../contexts/UIStore";
//Has title and loops subsections
const Container = styled.div`
	padding-bottom: ${({ spacingBottom }) => spacingBottom ?? null};
	${({ theme }) => ({
		backgroundColor: theme.background,
		color: theme.foreground,
	})};
`;

let sections = importDirectory(
	require.context("../SectionLayout/", true, /[a-zA-Z_-]*\.js$/),
	"default"
);
const Base = ({ data, index }) => {
	const uiState = useUIState();
	const localRef = useRef();
	const ref = useChapterLayout({ data, index });

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						uiState.setSection(data.menuTitle.join("<br />"));
					}
				});
			},
			{ rootMargin: "0px 0px -100% 0px" }
		);
		const themeObserver = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						uiState.addTheme(
							{ ...defaultTheme, ...(data.theme ?? {}) },
							data.keyId
						);
					} else {
						uiState.removeTheme(data.keyId);
					}
				});
			},
			{ rootMargin: "-100% 0px 0px 0px" }
		);
		observer.observe(localRef.current);
		themeObserver.observe(localRef.current);
		return () => {
			observer.disconnect();
			themeObserver.disconnect();
		};
	}, [uiState, data]);
	let combinedRef = useCallback(
		(node) => {
			ref(node);
			localRef.current = node;
		},
		[ref, localRef]
	);
	return (
		<ThemeProvider theme={{ ...defaultTheme, ...(data.theme ?? {}) }}>
			<Container ref={combinedRef} spacingBottom={data.spacingBottom}>
				<Title
					chapterKey={data.keyId}
					title={data.chapterTitle}
					subTitle={data.subtitle}
					meta={data.meta}
					layout={data.titleLayout}
					decoration={data.decoration}
					words={data.words}
				/>
				{data.sections &&
					data.sections.map((section, i) => {
						let Component = sections[section.component];
						return (
							<Component
								data={section}
								key={i}
								chapterKey={data.keyId}
								index={i + 1}
								chapter={data}
							/>
						);
					})}
			</Container>
		</ThemeProvider>
	);
};

export default Base;
