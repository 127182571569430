import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import LidarManager from "./LidarManager";
import { usePageLayout } from "../../../contexts/PageLayout";
import { autorun } from "mobx";
import { disablePageScroll, enablePageScroll, queue } from "scroll-lock";
import { Helmet } from "react-helmet";

const Canvas = styled.canvas`
	position: fixed;
	top: 0;
	left: 0;
	z-index: 2;
	width: 100%;
	height: 100%;
	background-color: #f4f4f4;
`;

const Button = styled.div`
	padding: 10px 20px 10px 43px;
	background: white;
	border-radius: 100px;
	position: relative;
	cursor: pointer;
	&:before {
		content: "";
		position: absolute;
		width: 24px;
		height: 24px;
		border-radius: 100px;
		top: 0;
		bottom: 0;
		left: 10px;
		margin: auto;
		background: ${(props) => (props.isRecording ? "red" : "grey")};
	}
`;

const Controls = styled.div`
	position: fixed;
	bottom: 20px;
	left: 20px;
	right: 20px;
	z-index: 1000;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
`;

const TextArea = styled.textarea`
	resize: none;
`;

const Instructions = styled.p`
	color: white;
	font-size: 13px;
	margin-bottom: 5px;
`;

const Lidar = () => {
	const ref = useRef();
	const manager = useRef(null);
	const pageLayout = usePageLayout();
	const [isRecording, setIsRecording] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);
	const [recordedMovementData, setRecordedMovementData] = useState(null);
	const onRecordButtonClick = () => {
		setIsRecording((isRecording) => !isRecording);
	};
	const onKeyUp = (event) => {
		switch (event.code) {
			case "KeyK":
				onRecordButtonClick();
		}
	};
	useEffect(() => {
		if (!manager.current) {
			disablePageScroll();
			manager.current = new LidarManager({
				canvas: ref.current,
				pageLayout,
				onLoad: () => {
					enablePageScroll();
					setIsLoaded(true);
					setTimeout(() => {
						pageLayout.setLoaded(true);
					}, 1000);

					if (window.location.hash) {
						const el = document.querySelector(window.location.hash);
						if (el) {
							el.scrollIntoView();
						}
					}
				},
			});
			window.LidarManager = manager.current;
		} else {
			//update manager properties as needed
		}
		window.addEventListener("keyup", onKeyUp);
		return () => {
			window.removeEventListener("keyup", onKeyUp);
			manager.current.cleanup();
		};
	}, []);

	useEffect(() => {
		return autorun(
			() => {
				manager.current.backgroundColors = pageLayout.backgroundColors;
			},
			{ delay: 100 }
		);
	}, []);

	return (
		<>
			<Helmet
				bodyAttributes={{
					class: isLoaded ? "no-grain" : "is-loading no-grain",
				}}
			/>
			<Canvas ref={ref} style={{ zIndex: isLoaded ? 2 : 400 }} />
		</>
	);
};

export default Lidar;
