import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import Cover from "./PrimaryCover/Cover";
import Quote from "./PrimaryCover/Quote";
import { useChapterLayout } from "../../util/useChapterLayout";
import { defaultTheme } from "./themes";
import { useUIState } from "../../contexts/UIStore";
import { observer } from "mobx-react-lite";
import { usePageLayout } from "../../contexts/PageLayout";

const Container = styled.div`
	height: 350vh;
	background-color: #f4f4f4;
`;
const Sticky = styled.div`
	position: sticky;
	top: 0;
	height: 100vh;
	z-index: 3;
`;
const PrimaryCover = observer(() => {
	const [showCover, setShowCover] = useState(true);
	const [showQuote, setShowQuote] = useState(false);
	const localRef = useRef();
	const uiState = useUIState();
	const pageLayout = usePageLayout();
	const data = useRef({
		keyId: "cover",
		theme: { foreground: "#141414", background: "#F4F4F4" },
	});
	const ref = useChapterLayout({
		data: data.current,
		index: 0,
	});
	useEffect(() => {
		const cb = () => {
			const winHeight = window.innerHeight;
			const scrollY = window.scrollY;
			if (scrollY >= 0 && scrollY < winHeight) {
				setShowCover(true);
			} else {
				setShowCover(false);
			}
			if (scrollY > winHeight * 1.25 && scrollY < winHeight * 2) {
				setShowQuote(true);
			} else {
				setShowQuote(false);
			}
		};
		window.addEventListener("scroll", cb);
		return () => {
			window.removeEventListener("scroll", cb);
		};
	}, []);

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						uiState.addTheme({ ...defaultTheme }, "cover");
						uiState.setSection(`economic opportunities<br />for our avatars`);
					} else {
						uiState.removeTheme("cover");
					}
				});
			},
			{ rootMargin: "-50% 0px -50%" }
		);
		observer.observe(localRef.current);
		return () => {
			observer.disconnect();
		};
	}, [uiState]);

	const cbRef = useCallback(
		(node) => {
			ref(node);
			localRef.current = node;
		},
		[ref, localRef]
	);

	return (
		<Container ref={cbRef}>
			<Sticky>
				<Cover show={pageLayout.loaded ? showCover : false} />
				<Quote show={showQuote} />
			</Sticky>
		</Container>
	);
});

export default PrimaryCover;
